<template>
    <el-dialog :title="!dataForm.recId ? '新增类型' : '修改类型'" :close-on-click-modal="false" :visible.sync="visible"
        width="480px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="类型名称" prop="kindName" class="item">
                        <el-input class="selItemInput" v-model="dataForm.kindName" placeholder="请输入" clearable
                            style="width:300px">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row class="formBtnGroup">
            <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
        </el-row>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "file-setting-add-or-update",
    data() {
        return {
            visible: false,
            inline: false,
            action: "",
            kindType: '',
            dataForm: {
                recId: '',
                kindName: '',
            },
            dataRule: {
                kindName: [
                    { required: true, message: "请填写类型名称", trigger: "change" }
                ],
            },
        }
    },
    methods: {
        init(id, kindType) {
            this.visible = true;
            this.dataForm.recId = id ? id : 0;
            this.kindType = kindType
            this.$nextTick(() => {
                this.$refs["dataForm"].resetFields();
                if (this.dataForm.recId) {
                    this.$http({
                        url: this.$store.state.httpUrl + `/business/filekindconfig/info/${this.dataForm.recId}`,
                        method: 'get',
                        params: this.$http.adornParams({
                            recId: this.dataForm.recId
                        })
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.dataForm = data.body;
                        }
                    })
                }
            })
        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/filekindconfig/save";
                    if (this.dataForm.recId && this.dataForm.recId !== 0) {
                        _url = "/business/filekindconfig/update";
                    }
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.$http.adornData({
                            workNo: $common.getItem("workNo"),
                            kindName: this.dataForm.kindName,
                            recId: this.dataForm.recId,
                            kindType: this.kindType,
                            defaultFlg: "0",
                            parentId: this.dataForm.parentId
                        })
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$parent.getDataList();
                                }
                            });
                        }
                    });
                }
            });
        }

    }
}
</script>
<style scoped>
.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}
</style>
